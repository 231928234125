













































































import { Vue, Component } from "vue-property-decorator";

import db from "./../../db";
import store from "./../../store";

@Component({})
export default class EmailConfiguration extends Vue {
    emailSettings: Array<any> = [];
    emailAddress = '';
    selectedUserID = '';
    errorMessage = '';

    goToNylasHostedAuth(): void {
      let linkToNylasHostedAuth = (process.env.NYLAS_HOSTED_AUTH_URI || 'https://api.nylas.com/oauth/authorize') + '?';
      const params = {
        'login_hint': this.emailAddress,
        'client_id': process.env.NYLAS_APP_CLIENT_ID || 'sb1nianoya4z5cqsy4pwwbvd',
        'response_type': 'token',
        'redirect_uri': process.env.NYLAS_REDIRECT_URI || 'https://app.yellowq.nl/nylas-web-hook',
        'scopes': 'email.read_only,email.modify,email.send',
        'state': 'CSRF_TOKEN'
      };
      Object.keys(params).map(key => {
        if(params[key]) {
          linkToNylasHostedAuth = linkToNylasHostedAuth.concat(key + '=' + params[key] + '&');
        }
      });
      (window as any).location = linkToNylasHostedAuth;
    }

    clearEmailConfig(): void {
      this.emailAddress = '';
    }

    async getEmailSettings() {
      await db
        .collection(`/tenants/${store.state.tenantID}/settings/email/addresses`)
        .get()
        .then((resp) => {

          if (resp && typeof resp !== "undefined") {
            const result: any[] = [];

            resp.forEach((r: any) => {
              const pushObj = r.data();
              pushObj.id = r.id;
              result.push(pushObj);
            });

            this.emailSettings = result;
          }

        })
        .catch(() => {
          this.emailSettings = [];
        });
    }

    async updateEmailSettings(emailSettings): Promise<void> {
      return await db
        .collection(`/tenants/${store.state.tenantID}/settings/email/addresses`)
        .doc(emailSettings.ID)
        .update(emailSettings)
        .then(() => {
          this.$store.dispatch('settings/getEmailSettings');
        })
        .catch(() => {});
    }

    get users() {
      return store.state.dynamic.users;
    }

    getUserDataByID(ID: string) {
      let user = this.users.find((el: any) => el.ID === ID);
      if (!user) {
        user = 'not found';
      }
      return user.name + ' (' + user.email + ')';
    }

    async addUserToEmailSettings(emailSettingsID: string): Promise<void> {
      this.errorMessage = '';

      const settings: any = this.emailSettings.find((el: any) => el.ID === emailSettingsID);
      const hasUserEmailSettings = this.emailSettings.find((el: any) => el.allowedUsers.includes(this.selectedUserID));

      if(hasUserEmailSettings) {
        this.errorMessage = 'Sorry, this user is subscribed to a different email address.';
      }

      if(!settings.allowedUsers.includes(this.selectedUserID) && !hasUserEmailSettings) {
        settings.allowedUsers.push(this.selectedUserID);
        await this.updateEmailSettings(settings);
      }
    }

    async removeUserFromEmailSettings(emailSettingsID: string, userID: string): Promise<void> {
      this.errorMessage = '';

      const settings: any = this.emailSettings.find((el: any) => el.ID === emailSettingsID);
      if(settings.allowedUsers.includes(userID)) {
        settings.allowedUsers = settings.allowedUsers.filter((el: string) => el !== userID);
        await this.updateEmailSettings(settings);
      }
    }

    async mounted() {
      await this.getEmailSettings();
    }
}
