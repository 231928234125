




























































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
  import {State} from "vuex-class";
  import store from '@/store';
  import debounce from 'debounce';
  import BaseFieldRelated from "@/components/Base/BaseFieldRelated.vue";
  import PdfTools from "@/components/PDFgenerator/PdfTools";
  import UnlayerTools, {UnlayerSettings} from "@/components/Unlayer/UnlayerTools.ts";
  import TemplateConstructor from '@/components/Unlayer/TemplateConstructor.vue';
  import ListValues from "@/components/Mixin/ListValues";

  class CurrentTemplateClass {
    id = null;
    ID = null;
    module = '';
    type = '';
    name = '';
    design = null;
    userID = '';
    subject = '';
    to = '';
    cc = '';
    bcc = '';
  }

  class SelectedRecordClass {
    ID = "";
    name = "";
  }

  @Component({
    components: {TemplateConstructor, BaseFieldRelated}
  })

  export default class EmailTemplates extends Vue {
    @State("tenantID") tenantID;
    @State("dynamicModules") dynamicModules;
    @Prop(Boolean) isUserEdit;
    columns = [
      {
        name: 'name',
        label: this.$t('email.headers.name'),
        field: 'name',
        align: 'left',
        sortable: true,
      },
      {
        name: 'type',
        label: this.$t('email.headers.type'),
        field: 'type',
        align: 'left',
        sortable: true,
      },
      {
        name: 'module',
        label: this.$t('email.headers.module'),
        field: 'module',
        align: 'left',
        sortable: true,
      },
      {
        name: 'editor',
        label: '',
        field: 'editor',
        align: 'center',
        sortable: true,
      },
      {
        name: 'delete',
        label: '',
        field: 'delete',
        align: 'center',
        sortable: true,
      },
      {
        name: 'copy',
        label: '',
        field: 'copy',
        align: 'center',
        sortable: true,
      },
      {
        name: 'modified',
        label: this.$t('email.headers.modifiedAt'),
        field: 'modified',
        align: 'center',
        sortable: true,
      }
    ];
    pagination = {
      rowsPerPage: 20
    };
    showCreateDialog = false;
    showDeleteDialog = false;
    showCopyDialog = false;
    showSelectedModule = false;
    newTemplateName = '';
    showModalEditor = false;
    showSelectRecordDialog = false;
    editorLink = '';
    currentTemplate = new CurrentTemplateClass();
    inputJson = null;
    selectedModule = '';
    emailSubject = '';
    emailToFields = '';
    emailCC_Fields = '';
    emailBCC_Fields = '';
    selectedRecord = new SelectedRecordClass();
    formData = {};
    record = {};
    loading = false;
    mergeTags = {};
    designTags = {};
    designTagsDB = {};
    settings = new UnlayerSettings();
    templates = [];
    userTemplates = [];
    tenantTemplates = [];
    globalTemplates = [];
    currentDesign: any = null;
    recordSelectKey = 1;

    async mounted() {
      this.loading = true;
      await this.getSettings();
      await this.getAllTemplates();
      this.loading = false;
    }

    async getAllTemplates() {
      (this.globalTemplates as any) = await UnlayerTools.getGlobalTemplates();
      (this.tenantTemplates as any) = await UnlayerTools.getTenantTemplates();
      (this.userTemplates as any) = await UnlayerTools.getUserTemplates();
      this.templates = [].concat(this.globalTemplates, this.tenantTemplates, this.userTemplates);
    }

    async getSettings() {
      (this.settings as any) = await UnlayerTools.getSettings();
    }

    get relatedModule() {
      return this.currentTemplate.module;
    }

    async onTemplateCreate() {
      this.currentTemplate = new CurrentTemplateClass();
      this.currentTemplate.module = this.selectedModule || '';
      this.currentTemplate.subject = this.emailSubject || '';
      this.currentTemplate.to = this.emailToFields || '';
      this.currentTemplate.cc = this.emailCC_Fields || '';
      this.currentTemplate.bcc = this.emailBCC_Fields || '';
      await this.saveTemplate(this.currentTemplate);
      this.eraseTheFormFields();
      store.state.alertMessage = "add";
    }

    eraseTheFormFields() {
      this.newTemplateName = '';
      this.selectedModule = '';
      this.emailSubject = '';
      this.emailToFields = '';
      this.emailCC_Fields = '';
      this.emailBCC_Fields = '';
    }

    onClickCopyBtn(props) {
      this.showCopyDialog = true;
      this.currentTemplate = JSON.parse(JSON.stringify(props.row));
    }

    async onTemplateCopy(): Promise<void> {
      this.currentTemplate.id = null;
      this.currentTemplate.ID = null;
      await this.saveTemplate(this.currentTemplate);
      this.currentTemplate = new CurrentTemplateClass();
      this.newTemplateName = '';
      store.state.alertMessage = "add";
    }

    async saveTemplate(template) {
      const currentDesign = JSON.parse(JSON.stringify(template));
      currentDesign.name = this.newTemplateName;
      currentDesign.updatedAt = new Date().toISOString();
      currentDesign.userID = this.isUserEdit ? store.state.userID : 'tenant';
      delete(currentDesign.type);
      const res = await UnlayerTools.saveTemplate(currentDesign);
      await this.getAllTemplates();
      return res;
    }

    async saveTemplateConstructor(template) {
      const currentDesign = JSON.parse(JSON.stringify(template));
      currentDesign.updatedAt = new Date().toISOString();
      delete(currentDesign.type);
      const res = await UnlayerTools.saveTemplate(currentDesign);
      store.state.alertMessage = "update";
      await this.getAllTemplates();
      return res;
    }

    async saveTemplateRename(templateName) {
      const saveData = {
        ID: this.currentTemplate.ID,
        userID: this.currentTemplate.userID,
        name: templateName
      };
      await UnlayerTools.saveTemplate(saveData);
      store.state.alertMessage = "update";
      await this.getAllTemplates();
      this.currentTemplate.name = templateName;
    }

    onCancelCreateDialog(){
      this.eraseTheFormFields();
    }

    onClickDeleteBtn(props){
      this.showDeleteDialog = true;
      this.currentTemplate = props.row;
    }

    async onTemplateDelete(): Promise<void> {
      await UnlayerTools.deleteTemplate(this.currentTemplate);
      await this.getAllTemplates();
      this.currentTemplate = new CurrentTemplateClass();
      store.state.alertMessage = "delete";
    }

    async openEditor(props) {
      this.currentTemplate = props.row;
      this.selectedModule = this.currentTemplate.module;
      this.emailSubject = this.currentTemplate.subject || '';
      this.emailToFields = this.currentTemplate.to || '';
      this.emailCC_Fields = this.currentTemplate.cc || '';
      this.emailBCC_Fields = this.currentTemplate.bcc || '';

      if (this.selectedModule) {
        this.formData = await PdfTools.getFormDataForPDF(this.selectedModule);
        this.mergeTags = this.getTagsFormData(this.formData);
      } else {
        this.formData = {};
      }
      this.showSelectRecordDialog = true;
    }

    async onRecordSelect() {
      if (this.selectedModule) {
        this.currentTemplate.module = this.selectedModule;
        //console.log(this.selectedModule);
      }
      if (this.selectedModule && this.selectedRecord) {
        const formData = await PdfTools.getFormDataForPDF(this.currentTemplate.module, this.selectedRecord.ID);
        const inputJson = PdfTools.getInputJsonForPDF(formData);
        this.mergeTags = this.getTagsFormData(this.formData);
        //console.log(inputJson);
        this.onTemplateEdit(inputJson);
      } else {
        this.onTemplateEdit();
      }
      this.selectedRecord = new SelectedRecordClass;
    }

    async onSelectModule() {
      this.formData = await PdfTools.getFormDataForPDF(this.selectedModule);
      this.recordSelectKey++;
      this.selectedRecord = new SelectedRecordClass;
      this.updateTemplate();
    }

    updateTemplate = debounce(() => {
      const saveData = {
        ID: this.currentTemplate.ID,
        userID: this.currentTemplate.userID,
        module: this.selectedModule || '',
        subject: this.emailSubject || '',
        to: this.emailToFields || '',
        cc: this.emailCC_Fields || '',
        bcc: this.emailBCC_Fields || '',
      };
      console.log(saveData);
      UnlayerTools.saveTemplate(saveData).then(() => { this.getAllTemplates(); });
    }, 500);

    onTemplateEdit(inputJson = null) {
      this.inputJson = inputJson;
      this.showModalEditor = true;
    }

    getTagsFormData(formData) {
      const fields: any = ListValues.getValidColumns(formData.dynamicFields);
      const tags  = {};
      //console.log(formData.data);
      for(const fname in fields) {
        let sname = '';
        // eslint-disable-next-line no-prototype-builtins
        if (formData.data[fname] && formData.data[fname].hasOwnProperty('name')) {
          sname = '.name';
        }
        tags[fname] = {name: fields[fname].label, value: '{{' + fname + sname + '}}'}
      }
      //console.log(tags);
      return tags;
    }

    onCancelSelectedRecordDialog(){
      this.eraseTheFormFields();
      this.selectedRecord = new SelectedRecordClass();
      this.currentTemplate = new CurrentTemplateClass();
    }

    onCancelCopyDialog(){
      this.eraseTheFormFields();
      this.currentTemplate = new CurrentTemplateClass();
    }

    onCancelDeleteDialog(){
      this.currentTemplate = new CurrentTemplateClass();
    }

    get curTenantID() {
      return this.tenantID;
    }

    get modulesList() {
      return Object.keys(this.dynamicModules);
    }

    converDate(isostr) {
      if (!isostr) {
        return '';
      }
      const date = new Date(isostr);
      return date.toLocaleString('nl-Nl')
    }

    convertName(type) {
      if (type === 'global') {
        return 'YellowQ';
      } else {
        return type;
      }
    }

    canEditTemplate(props) {
      const template = props.row;
      return template && template.type !== 'global';
    }


    getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
      return `${firstRowIndex}-${endRowIndex} ${this.$t(
        "table.misc.of"
      )} ${totalRowsNumber}`;
    }

  }
