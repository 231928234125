import { render, staticRenderFns } from "./FormBuilderView.vue?vue&type=template&id=aaef8cb6&scoped=true&"
import script from "./FormBuilderView.vue?vue&type=script&lang=js&"
export * from "./FormBuilderView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aaef8cb6",
  null
  
)

export default component.exports
import {QCard,QToolbar,QSpace,QBtn,QTable,QTr,QTd,QDialog,QCardSection,QInput,QCardActions,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QToolbar,QSpace,QBtn,QTable,QTr,QTd,QDialog,QCardSection,QInput,QCardActions})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
