
















































































































































































































import {Component, Vue} from "vue-property-decorator";
import {State} from "vuex-class";
import functions from '../../functions';
import store from '@/store';
import QPdfviewer from '@/components/PDFgenerator/QPdfviewer';
import BaseFieldRelated from "@/components/Base/BaseFieldRelated.vue";
import PdfTools from "@/components/PDFgenerator/PdfTools";

class CurrentTemplateClass {
  id = null
  tags: Array<string> = []
}

class SelectedRecordClass {
  ID = "";
  name = "";
}

@Component({
  components: {QPdfviewer, BaseFieldRelated}
})
export default class PdfTemplates extends Vue {
  @State("tenantID") tenantID;
  @State("dynamicModules") dynamicModules;
  templates = [];
  columns = [
    {
      name: 'name',
      label: this.$t('pdf.headers.name'),
      field: 'name',
      align: 'left',
      sortable: true,
    },
    {
      name: 'module',
      label: this.$t('pdf.headers.module'),
      field: 'module',
      align: 'left',
      sortable: true,
    },
    {
      name: 'editor',
      label: '',
      field: 'editor',
      align: 'center',
      sortable: true,
    },
    {
      name: 'delete',
      label: '',
      field: 'delete',
      align: 'center',
      sortable: true,
    },
    {
      name: 'copy',
      label: '',
      field: 'copy',
      align: 'center',
      sortable: true,
    },
    {
      name: 'modified',
      label: this.$t('pdf.headers.changedAt'),
      field: 'modified',
      align: 'center',
      sortable: true,
    }
  ];
  pagination = {
    rowsPerPage: 20
  };
  loading = false;
  showCreateDialog = false;
  showDeleteDialog = false;
  showCopyDialog = false;
  newTemplateName = '';
  showModalEditor = false;
  showSelectRecordDialog = false;
  editorLink = '';
  currentTemplate = new CurrentTemplateClass();
  selectedModule = '';
  selectedRecord = new SelectedRecordClass();
  formData = {};
  record = {};

  get relatedModule() {
    return this.currentTemplate.tags[0];
  }

  async getFormData() {
    this.formData = await PdfTools.getFormDataForPDF(this.currentTemplate.tags[0]);
  }

  async openEditor(props) {
    this.currentTemplate = props.row;
    if (this.currentTemplate.tags[0]) {
      await this.getFormData();
      this.showSelectRecordDialog = true;
    } else {
      this.onTemplateEdit()
    }
  }

  onClickDeleteBtn(props){
    this.showDeleteDialog = true;
    this.currentTemplate = props.row;
  }
  onClickCopyBtn(props){
    this.showCopyDialog = true;
    this.currentTemplate = props.row;
  }

  async getAllTemplates(): Promise<void> {
    const generatePDF = functions.httpsCallable('GeneratePDF');

    const options = {
      tenant: this.curTenantID,
      request: 'getAllTemplates'
    };

    await generatePDF(options).then((res: any) => {
      const data = res.data;
      this.templates = data;
      //console.log(data);
    }).catch(error => {
      console.log('error: ', error);
      this.templates = [];
    });
  }

  async onTemplateCreate(): Promise<void> {
    const generatePDF = functions.httpsCallable('GeneratePDF');

    const options = {
      tenant: this.curTenantID,
      name: this.newTemplateName,
      tag: this.selectedModule,
      request: 'createTemplate'
    };
    this.newTemplateName = '';
    this.selectedModule = '';

    await generatePDF(options).then((res: any) => {
      const data = res.data;
      //console.log('createTemplate', data);
      this.getAllTemplates()
    }).catch(error => {
      console.log('error: ', error);
    });
  }

  async onRecordSelect() {
    const formData = await PdfTools.getFormDataForPDF(this.currentTemplate.tags[0], this.selectedRecord.ID);
    const inputJson = PdfTools.getInputJsonForPDF(formData);
    this.selectedRecord = new SelectedRecordClass;
    console.log(inputJson);
    this.onTemplateEdit(inputJson)
  }

  async onTemplateEdit(inputJson = ''): Promise<void> {
    const generatePDF = functions.httpsCallable('GeneratePDF');

    const options = {
      tenant: this.curTenantID,
      templateID: this.currentTemplate.id,
      language: 'en',
      inputJSON: inputJson,
      request: 'openEditor'
    };
    this.currentTemplate = new CurrentTemplateClass();

    await generatePDF(options).then((res: any) => {
      const data = res.data;
      // console.log('openEditor', data);
      this.showModalEditor = true;
      this.editorLink = data
    }).catch(error => {
      console.log('error: ', error);
    });
  }

  async onTemplateDelete(): Promise<void> {
    const generatePDF = functions.httpsCallable('GeneratePDF');

    const options = {
      tenant: this.curTenantID,
      templateID: this.currentTemplate.id,
      request: 'deleteTemplate'
    };
    await generatePDF(options).then((res: any) => {
      const data = res.data;
      //console.log('deleteTemplate', data);
      this.getAllTemplates()
    }).catch(error => {
      console.log('error: ', error);
    });
    this.currentTemplate = new CurrentTemplateClass();
  }

  async onTemplateCopy(): Promise<void> {
    const generatePDF = functions.httpsCallable('GeneratePDF');

    const options = {
      tenant: this.curTenantID,
      templateID: this.currentTemplate.id,
      name: this.newTemplateName,
      request: 'copyTemplate'
    };
    this.newTemplateName = '';

    await generatePDF(options).then((res: any) => {
      const data = res.data;
      //console.log('copyTemplate', data);
      this.getAllTemplates()
    }).catch(error => {
      console.log('error: ', error);
    });
    this.currentTemplate = new CurrentTemplateClass();
  }

  canEditTemplate(props) {
    const template = props.row;
    return template && template.owner;
  }

  onCancelCreateDialog(){
    this.newTemplateName = '';
    this.selectedModule = '';
  }

  onCancelSelectedRecordDialog(){
    this.selectedRecord = new SelectedRecordClass();
    this.currentTemplate = new CurrentTemplateClass();
  }

  onCancelCopyDialog(){
    this.newTemplateName = '';
    this.currentTemplate = new CurrentTemplateClass();
  }

  onCancelDeleteDialog(){
    this.currentTemplate = new CurrentTemplateClass();
  }

  async mounted() {
    this.loading = true;
    await this.getAllTemplates();
    this.loading = false;
  }

  get initialized() {
    return store.state.initialization;
  }

  get curTenantID() {
    return this.tenantID;
  }

  get modulesList() {
    return Object.keys(this.dynamicModules);
  }

  getPaginationLabel(firstRowIndex, endRowIndex, totalRowsNumber) {
    return `${firstRowIndex}-${endRowIndex} ${this.$t(
      "table.misc.of"
    )} ${totalRowsNumber}`;
  }

}
