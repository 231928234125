


























































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import EmailConfiguration from "@/components/Nylas/EmailConfiguration.vue";
import DefaultColumns from "@/components/Settings/DefaultColumns.vue";
import TemplateConstructor from "@/components/Unlayer/TemplateConstructor.vue";
import PdfTemplates from "@/components/PDFgenerator/PdfTemplates.vue";
import EmailTemplates from "@/components/Unlayer/EmailTemplates.vue";
import FormBuilderView from "@/components/Builder/FormBuilderView.vue";

@Component({
  components: {EmailConfiguration, DefaultColumns,
    TemplateConstructor, PdfTemplates, EmailTemplates, FormBuilderView},
})

export default class Settings extends Vue {
  @Prop(String) userType;
  @Prop(String) action;
  panel = 'admin';
  options = [
      {label: 'Administrator', value: 'admin'},
      {label: 'User', value: 'user'},
  ];
  adminTab = 'defaultColumns';
  userTab = 'emailConfiguration';
  templateSettingsTab = 'settings';
  adminSplitterModel = 200;
  userSplitterModel = 200;

  beforeMount() {
    if (!this.userType || this.userType === 'admin') {
      this.adminTab = this.action || 'defaultColumns';
    } else {
      this.userTab = this.action || 'emailConfiguration';
    }
    //console.log(this.userType);
    //console.log(this.action);
  }

  @Watch('adminTab')
  setAdminTab() {
    this.$router.push({
      name: "settings",
      params: {userType: this.userType || 'admin', action: this.adminTab},
    }).catch(err => {});
  }

}
