//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState, mapActions } from 'vuex'
  import db from "../../db";
  import store from "../../store";
  import draggable from 'vuedraggable';
import ListValues from '@/components/Mixin/ListValues'

  export default {
    name: "DefaultColumns",
    components: {draggable},
    data: () => ({
      messageOk: false,
      messageError: false,
      columns: null,
      dynamicColumns: {},
    }),
    mounted() {
      this.getColumns();
    },

    computed: {
      ...mapState(["dynamicModules"]),
    },
    methods: {
      ...mapActions("settings", ["getTenantDefaultColumns", "setTenantDefaultColumns"]),

      async getColumns() {
        const dataDoc = await this.getTenantDefaultColumns();
        const columns = dataDoc.data() ?? {};
        const result = {};
        const dynColumns = {};
        for (const i in this.dynamicModules) {
          const module = this.dynamicModules[i];
          dynColumns[i] = {
            title : module.title ?? i,
            fields : ListValues.getValidColumns(module.fields)
          };
        }
        for (const i in dynColumns) {
          const module = dynColumns[i];
          result[i] = columns[i] ? columns[i].filter(c => Object.keys(module.fields).indexOf(c) !== -1) : [];
        }
        this.dynamicColumns = dynColumns;
        this.columns = result;
      },
      saveSettings() {
          this.setTenantDefaultColumns(this.columns).then(() => {
            store.state.alertMessage = "update";
          })
      },
    },
  };
